<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    aria-hidden="true"
  >
    <g>
      <path fill="none" d="M0 0h48v48H0z" />
      <path
        fill="currentColor"
        d="M41.921 43.912H6.078a1.992 1.992 0 0 1 0-3.983h35.843a1.992 1.992 0 0 1 0 3.983Z"
      />
      <path
        fill="currentColor"
        d="m12.653 25.493 9.956 9.857a1.892 1.892 0 0 0 2.788 0l9.956-9.857a2.091 2.091 0 0 0 .2-2.688 1.892 1.892 0 0 0-2.987-.2l-6.574 6.572V6.078a1.992 1.992 0 0 0-3.983 0v23.1l-6.571-6.571a1.892 1.892 0 0 0-2.987.2 2.091 2.091 0 0 0 .202 2.686Z"
      />
    </g>
  </svg>
</template>
